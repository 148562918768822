(function($) {

  var jsLoadProgress = true;
  $(window).on('load', function() {
    $('body').removeClass('js-load-progress');
    jsLoadProgress = false;
  });


  /**
   * ГЛАВНАЯ СТРАНИЦА
   */
  var mainPage = $('#mainFullpage'),
      sections = $('.main-fp__section'),
      sectionsCnt = sections.size(),
      sectionColorizedClass = 'main-fp__section--colorized',
      header = $('.header--main'),
      headerFadeClass = 'header--main-fade',
      headerBlackClass = 'header--main-black';
  var COLORIZED_MAX_ATTEPMTS = 30;

  if(mainPage.length) {
    mainPage.fullpage({
      paddingBottom: '117px',
      scrollBar: true,

      onLeave: function(index, nextIndex, direction) {
        if(
          direction === 'down' && nextIndex === sectionsCnt ||
          direction === 'up' && nextIndex === sectionsCnt - 1
        ) {
          header.addClass(headerFadeClass);
        }
      },

      afterLoad: function(anchorLing, index) {
        sections.removeClass(sectionColorizedClass);

        var colorizedInterval,
            colorizedAttempts = 0;
        if( !jsLoadProgress ) {
          sections.eq(index-1).addClass(sectionColorizedClass);
        } else {
          colorizedInterval = setInterval(function() {
            if( !jsLoadProgress || colorizedAttempts === COLORIZED_MAX_ATTEPMTS) {
              sections.eq(index-1).addClass(sectionColorizedClass);
              colorizedAttempts = 0;
              clearInterval(colorizedInterval);
            }
            ++colorizedAttempts;
          }, 100);
        }

        if(index === sectionsCnt) {
          header.removeClass(headerFadeClass);
          header.addClass(headerBlackClass);
        } else {
          header.removeClass(headerBlackClass);
          header.removeClass(headerFadeClass);
        }
      },

      afterRender: function() {
        console.info('FullPage.js was rendered!');
      }
    });
  }


  /**
   * КАРТА ДЛЯ КОНТАКТОВ
   */
  var contactsMaps = $('.js-map');
  contactsMaps.each(function(i) {
    var mapContainer = $(this).attr('id'),
        center = $('#' + mapContainer).data('center'),
        zoom = $('#' + mapContainer).data('zoom');

    var map = L.map(mapContainer, {scrollWheelZoom: false}).setView(center, zoom);

    var tileLayer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      filter: function () {
        new L.CSSFilter(this, {
            filters: ['grayscale(100%)']
        }).render();
      }
    });

    var marker = L.marker(
      center,
      {
        icon: L.icon({
          iconUrl: 'images/map_marker.png',
          iconSize: [23, 28]
        })
      }
    );

    tileLayer.addTo(map);
    marker.addTo(map);
  });


  /**
   * ЛАЙТБОКС ДЛЯ ФОТО
   */
  var fancys = $('.js-fancys');
  fancys.fancybox({
    openEffect  : 'none',
    closeEffect : 'none',
    tpl: {
      error: '<p class="fancybox-error">Невозможно загрузить запрошенный ресурс.<br>Пожалуйста, попробуйте позже</p>',
      closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>',
      next: '<a title="Вперед" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
      prev: '<a title="Назад" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
    }
  });


  /**
   * ТЯГАНИЕ ФИКСИРОВАННЫХ МЕНЮ
   */
  var fixed = $('.js-fixed'),
      fixedPositionTop;

  if(fixed.length) {
    fixedPositionTop = fixed.position().top;

    $(document).on('scroll', function(e) {
      var doc = $(this);
      if(doc.scrollLeft()) {
        fixed.css("left", -doc.scrollLeft());
      } else {
        fixed.removeAttr('style');
      }
    }).trigger('scroll');
  }

})(jQuery);